import { countries } from "countries-list";
import {
  activityOptions,
  improvementOptions,
  sufferFromOptions,
} from "./questionnaireOptions";
// mock

const getCountryOptions = () => {
  return Object.entries(countries || []).map(([code, name]) => ({
    value: name.name,
    label: name.name,
  }));
};
const inputs = [
  // General Questions
  {
    name: "gender",
    label: "What is your gender (assigned from birth)?",
    type: "radio",
    options: ["Male", "Female"],
    value: "",
    step: 0,
    required: true,
  },
  {
    name: "date_of_birth",
    label: "What year were you born?",
    type: "date",
    value: "",
    step: 0,
    required: true,
  },
  {
    name: "origin",
    label: "What Nationality are you? (select multiple if required)",
    type: "select",
    options: getCountryOptions(),
    value: "",
    step: 0,
    required: true,
  },
  {
    name: "ethnicity",
    label: "What is your ethnicity?",
    type: "radio",
    options: [
      "White, Caucasian",
      "Asian",
      "Black, African, Caribbean",
      "Arab",
      "Other",
    ],
    value: "",
    step: 0,
    required: true,
  },
  {
    name: "height",
    label: "What is your height? (cm)",
    placeholder: "180",
    type: "number",
    value: "",
    unit: "cm",
    step: 0,
    required: true,
  },
  {
    name: "weight",
    label: "What is your weight? (kg)",
    type: "number",
    placeholder: "87",
    value: "",
    unit: "kg",
    step: 0,
    required: true,
  },

  // Nutrition Questions
  {
    name: "dietaryConsiderations",
    label: "Do any of these dietary considerations apply to you?",
    type: "radio",
    options: ["Vegan", "Vegetarian", "Pescetarian", "None"],
    value: "",
    step: 1,
    required: true,
  },
  {
    name: "allergiesOrIntolerances",
    label: "Do you have any allergies or food intolerances?",
    type: "radio",
    options: ["Yes", "No"],
    value: "",
    step: 1,
    required: true,
  },
  {
    name: "allergiesOrIntolerancesType",
    label: "Is it one of these?",
    type: "radio",
    options: [
      "Lactose",
      "Gluten",
      "Nuts",
      "Seafood",
      "Pollen",
      "Soy",
      "None of These",
    ],
    value: "",
    step: 1,
    required: false,
  },
  {
    name: "caloricIntake",
    label: "What is your typical caloric intake (estimated)?",
    type: "radio",
    options: [
      "Less than 2000",
      "2000-2499",
      "2500-2999",
      "3000-3499",
      "3500-4000",
      "4000+",
    ],
    value: "",
    step: 1,
    required: true,
  },
  {
    name: "nutrition",
    label:
      "Thinking of a typical day of eating, please describe the following:",
    step: 1,
    subQuestions: [
      {
        name: "breakfast",
        placeholder: "Oatmeal",
        type: "text",
        value: "",
        required: true,
      },
      {
        name: "lunch",
        type: "text",
        placeholder: "Ham and cheese sandwich",
        value: "",
        required: true,
      },
      {
        name: "dinner",
        placeholder: "Steak and eggs",
        type: "text",
        value: "",
        required: true,
      },
      {
        name: "snacks",
        type: "text",
        placeholder: "Protein Shake (Myoform shake)",
        value: "",
        required: true,
      },
    ],
  },
  {
    name: "caffeinatedDrinks",
    label: "How many caffeinated drinks do you have a day?",
    type: "radio",
    options: ["None", "1-3", "4 or more"],
    value: "",
    step: 1,
    required: true,
  },
  {
    name: "personalizedNutrition",
    label: "When it comes to personalized nutrition, you're...",
    type: "radio",
    options: ["An expert", "Interested", "Skeptical"],
    value: "",
    step: 1,
    required: true,
  },
  {
    name: "supplements",
    label: "How many supplements do you tend to take a day?",
    type: "radio",
    options: ["None", "1-4", "5+"],
    value: "",
    step: 1,
    required: true,
  },
  {
    name: "supplementsDescription",
    label: "Describe your supplements if applicable.",
    type: "text",
    value: "",
    step: 1,
    required: false,
  },
  {
    name: "weightGoal",
    label: "Do you want to lose weight, gain weight, or stay as you are?",
    type: "radio",
    options: ["Lose weight", "Gain weight", "Stay as I am"],
    value: "",
    step: 1,
    required: true,
  },

  // Training Questions
  {
    name: "weeklyExerciseHours",
    label: "How many hours of exercise/training do you do each week?",
    type: "radio",
    options: [
      "1-5 hours",
      "6-10 hours",
      "11-15 hours",
      "16-20 hours",
      "20-25 hours",
      "26-30 hours",
      "30+ hours",
    ],
    value: "",
    step: 2,
    required: true,
  },
  {
    name: "trainingType",
    label: "What sport/type of training do you partake in?",
    type: "select",
    options: activityOptions,
    value: "",
    step: 2,
    required: true,
  },
  {
    name: "trainingTypeOther",
    label: "If other, can you detail which?",
    type: "text",
    value: "",
    step: 2,
    required: false,
  },
  {
    name: "improvementGoals",
    label: "What would you like to improve?",
    type: "select",
    options: improvementOptions,
    value: "",
    step: 2,
    required: true,
  },
  {
    name: "consumeSupplementsDuringTraining",
    label: "Do you often consume supplements during your training?",
    type: "radio",
    options: ["Yes", "No"],
    value: "",
    step: 2,
    required: true,
  },
  {
    name: "supplement_type",
    label:
      "What would you like a supplement to cover? What is most convenient to your exercise regime/program?",
    type: "radio",
    options: ["PRE", "INTRA", "POST"],
    value: "",
    step: 2,
    required: true,
  },
  {
    name: "sunExposure",
    label: "How often do you get out in the sunshine with skin exposed?",
    type: "radio",
    options: ["Rarely", "Some days", "All the time"],
    value: "",
    step: 2,
    required: true,
  },
  {
    name: "alcoholFrequency",
    label: "Do you drink alcohol 3 times a week or more?",
    type: "radio",
    options: ["Yes", "No", "I don’t drink"],
    value: "",
    step: 2,
    required: true,
  },
  {
    name: "smokingHabit",
    label: "Do you smoke?",
    type: "radio",
    options: ["Yes", "Only socially", "No"],
    value: "",
    step: 2,
    required: true,
  },
  {
    name: "focusIssues",
    label: "Do you often struggle to focus?",
    type: "radio",
    options: ["Yes", "No"],
    value: "",
    step: 2,
    required: true,
  },
  {
    name: "forgetfulness",
    label: "Do you often forget things?",
    type: "radio",
    options: ["Yes", "No"],
    value: "",
    step: 2,
    required: true,
  },
  {
    name: "currentSymptoms",
    label:
      "Do you currently suffer from: Stress, Low mood, Anxiety, Worry, None",
    type: "select",
    options: sufferFromOptions,
    value: "",
    step: 2,
    required: true,
  },

  // Sleep and Illness Questions

  {
    name: "sleepHours",
    label: "How many hours do you usually sleep a night?",
    type: "radio",
    options: ["Fewer than 7", "7-9", "More than 9"],
    value: "",
    step: 3,
    required: true,
  },
  {
    name: "commonIllnessFrequency",
    label:
      "Over the past year, how often have you been unwell (common cold/flu)?",
    type: "radio",
    options: ["Rarely", "2-3 times", "4+ times"],
    value: "",
    step: 3,
    required: true,
  },
  {
    name: "commonIllnessRecoveryTime",
    label:
      "How long does it usually take you to recover from illness (common cold)?",
    type: "radio",
    options: ["1-3 days", "4-6 days", "7+ days"],
    value: "",
    step: 3,
    required: true,
  },
  {
    name: "antibioticsFrequency",
    label: "How many times have you taken antibiotics in the last 2 years?",
    type: "radio",
    options: ["None or once", "Twice or more"],
    value: "",
    step: 3,
    required: true,
  },
  {
    name: "prescriptionMedicine",
    label: "Are you currently taking any prescription medicine?",
    type: "radio",
    options: ["Yes", "No", "Prefer not to say"],
    value: "",
    step: 3,
    required: true,
  },
  {
    name: "preExistingConditions",
    label: "Do you suffer from any pre-existing medical conditions?",
    type: "radio",
    options: ["Yes", "No", "Prefer not to say"],
    value: "",
    step: 3,
    required: true,
  },
  {
    name: "preExistingConditionsDescription",
    label: "If yes, please describe your pre-existing medical conditions.",
    type: "text",
    value: "",
    step: 3,
    required: false,
    multiline: true,
  },
];
export default inputs;
